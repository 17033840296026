import React, { useState, useRef, useEffect } from 'react';

function StepPersonalInfo({
  adults,
  setAdults,
  kids,
  setKids,
  notes,
  setNotes,
  setCompletionStatusInParent,
}) {
   
  const handleAddAdult = () => {
    setAdults([
      ...adults,
      {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        dob: '',
        age: '',
        monthlyNetIncome: '',
        monthlyInvestmentIncome: '',
        monthlyOtherIncome: '',
      },
    ]);
    setTimeout(() => {
      if (lastAddedFirstInputRefAdult.current) {
        lastAddedFirstInputRefAdult.current.focus();
      }
    }, 0);
  };

  const handleDeleteAdult = (index) => {
    const shouldDelete = window.confirm('Are you sure you want to delete this adult record?');
    if (shouldDelete) {
      const updatedAdults = [...adults];
      updatedAdults.splice(index, 1);
      setAdults(updatedAdults);
    }
  };

  const handleAddChild = () => {
    setKids([...kids, { firstName: '', lastName: '', dob: '', age: '' }]);
    setTimeout(() => {
      if (lastAddedFirstInputRefChild.current) {
        lastAddedFirstInputRefChild.current.focus();
      }
    }, 0);
  };

  const handleDeleteChild = (index) => {
    const shouldDelete = window.confirm('Are you sure you want to delete this child record?');
    if (shouldDelete) {
      const updatedKids = [...kids];
      updatedKids.splice(index, 1);
      setKids(updatedKids);
    }
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const calculateAge = (dob) => {
    const birthYear = new Date(dob).getFullYear();
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear;
  };
  
  const [emailAlertShown, setEmailAlertShown] = useState(false);
  const handleEmailBlur = (email, index) => {
    if (!emailAlertShown && email.trim() !== '' && !validateEmail(email)) {
      setEmailAlertShown(true);  // Set the flag before showing the alert
      alert('Please enter a valid email address.');

      setTimeout(() => {
        if (emailRef.current) {
          emailRef.current.focus();
          emailRef.current.select();
        }

        // Reset the flag so future alerts can be shown if necessary
        setEmailAlertShown(false);
      }, 0);

      return false;  // Email is invalid
    }

    return true;  // Email is valid or empty
};

  const calculateTotalHouseholdIncome = () => {
    const adultIncomes = adults.map(
      (adult) =>
        parseFloat(adult.monthlyNetIncome || 0) +
        parseFloat(adult.monthlyInvestmentIncome || 0) +
        parseFloat(adult.monthlyOtherIncome || 0)
    );
    const totalAdultIncome = adultIncomes.reduce((total, income) => total + income, 0);
    return totalAdultIncome;
  };

  const enforcePhonePattern = (e) => {
    const input = e.target;
    const sanitizedValue = input.value.replace(/[^0-9+() -]/g, '');
    const formattedValue = formatPhoneNumber(sanitizedValue);
    input.value = formattedValue;
  };
  

  const formatPhoneNumber = (inputValue) => {
    // Remove all non-numeric characters from the input value
    const numericValue = inputValue.replace(/[^0-9]/g, '');
  
    // Limit the numericValue to 10 characters
    const limitedValue = numericValue.slice(0, 10);
    
    // Check if the limitedValue has 10 digits
    if (limitedValue.length === 10) {
      // Format the phone number as "(XXX) XXX-XXXX"
      return `(${limitedValue.slice(0, 3)}) ${limitedValue.slice(3, 6)}-${limitedValue.slice(6, 10)}`;
    } else {
      // If it doesn't have 10 digits, return the limitedValue as is
      return limitedValue;
    }
  };
  
  
  
  const emailRef = useRef(null);
  const adultFields = [
    { label: "First Name *", type: "text", valueKey: "firstName", required: true },
    { label: "Last Name *", type: "text", valueKey: "lastName", required: true },
    { label: "Email *", type: "email", valueKey: "email", required: true, onBlur: handleEmailBlur },
    { label: "Phone", type: "text", valueKey: "phone"},
    { label: "Date of Birth *", type: "date", valueKey: "dob", required: true, isDob: true },
    { label: "Monthly Net Income *", type: "number", valueKey: "monthlyNetIncome", required: true, className: "input_dollar" },
    { label: "Monthly Investment Income", type: "number", valueKey: "monthlyInvestmentIncome", className: "input_dollar" },
    { label: "Monthly Other Income", type: "number", valueKey: "monthlyOtherIncome", className: "input_dollar" },
  ];

  const kidFields = [
    { label: "First Name *", type: "text", valueKey: "firstName", required: true },
    { label: "Last Name *", type: "text", valueKey: "lastName", required: true },
    { label: "Date of Birth *", type: "date", valueKey: "dob", required: true, isDob: true },
  ];
  
  const lastAddedFirstInputRefAdult = useRef(null);
  const lastAddedFirstInputRefChild = useRef(null);
   
  const calculateCompletionStatus = () => {
    let adultStatus = 0;
    let childStatus = 0;

    const hasKids = kids.length > 0;
    const adultWeightage = hasKids ? 0.8 : 1; // 80% if kids are added, else 100%
    const childWeightage = hasKids ? 0.2 : 0; // 20% if kids are added, else 0%

    // Dynamically fetch required fields for adults
    const requiredAdultFields = adultFields.filter(field => field.required).map(field => field.valueKey);

    // Check adult completion
    adults.forEach(adult => {
        requiredAdultFields.forEach(field => {
            const fieldInfo = adultFields.find(item => item.valueKey === field);
            
            if (fieldInfo.type === "email" && validateEmail(adult[field])) {
                adultStatus += adultWeightage / (adults.length * requiredAdultFields.length);
            } else if (fieldInfo.type === "number" && adult[field] && parseFloat(adult[field]) > 0) {
                adultStatus += adultWeightage / (adults.length * requiredAdultFields.length);
            } else if (adult[field] && fieldInfo.type !== "number" && fieldInfo.type !== "email") {
                adultStatus += adultWeightage / (adults.length * requiredAdultFields.length);
            }
        });
    });

    // Dynamically fetch required fields for kids
    const requiredKidFields = kidFields.filter(field => field.required).map(field => field.valueKey);

    // Check child completion (if any children are added)
    if (hasKids) {
        kids.forEach(kid => {
            requiredKidFields.forEach(field => {
                if (kid[field] && kid[field].trim() !== "") {
                    childStatus += childWeightage / (kids.length * requiredKidFields.length);
                }
            });
        });
    }

    return Math.round((adultStatus + childStatus) * 100); // Return as a percentage
  }

useEffect(() => {
  const status = calculateCompletionStatus();
  setCompletionStatusInParent(status); // Update the status in parent
}, [adults, kids]);

  return (
    <div className="personal-info-container">
      {adults.map((adult, index) => (
        <div className='form_tab' key={index}>
          <div className='delete_button' title="delete" onClick={() => handleDeleteAdult(index)}/>                     
          <h2>Personal Information → <span className='highlight_label'> Adult {index+1} </span></h2>

          {adultFields.map((field, fieldIndex) => (
            <span className='form_field' key={field.valueKey}>
              <label htmlFor={'adult'+index+fieldIndex}>
                {field.label}
                {field.isDob && ` → Age: ${adult.age}`}
              </label>
              <input 
                {...field.valueKey === 'phone' ? { onInput: enforcePhonePattern } : {}}
                className={field.className}
                type={field.type}                
                // value={adult[field.valueKey]}
                id={'adult'+index+fieldIndex}
                value={field.label === "Phone" ? formatPhoneNumber(adult[field.valueKey]) : adult[field.valueKey]}
                //set focus to first field when added new record
                ref={(el) => {
                  if (field.valueKey === 'email') {
                    emailRef.current = el;
                  } else if (fieldIndex === 0 && index === adults.length - 1) {
                    lastAddedFirstInputRefAdult.current = el;
                  }
                }}
                onChange={(e) => {
                  const updatedAdults = [...adults];
                  updatedAdults[index][field.valueKey] = e.target.value;
                  if (field.isDob) {
                    updatedAdults[index].age = calculateAge(e.target.value);
                  }
                  setAdults(updatedAdults);
                }}
                onBlur={field.onBlur ? (e) => {
                  const isValid = field.onBlur(e.target.value, index);
                  if (field.valueKey === 'email' && !isValid) {
                    emailRef.current.focus();
                    emailRef.current.select();
                  }
                } : undefined}
                required={field.required}                
              />
            </span>
          ))}
        </div>
      ))}
        
      {kids.map((kid, index) => (
        <div className='form_tab' key={index}>
          <button className='delete_button' title="delete" onClick={() => handleDeleteChild(index)}/>                     
          <h2>Child Information → <span className='highlight_label highlight_kid'> Child {index+1} </span></h2>          
          {kidFields.map((field, fieldIndex) => (
            <span className='form_field' key={field.valueKey}>
              <label htmlFor={'kid'+index+fieldIndex}>
                {field.label}
                {field.isDob && ` → Age: ${kid.age}`}
              </label>
              <input
                id={'kid'+index+fieldIndex}
                type={field.type}
                value={kid[field.valueKey]}      
                //set focus to first field when added new record
                ref={fieldIndex === 0 && index === kids.length - 1 ? lastAddedFirstInputRefChild : null}                
                onChange={(e) => {
                  const updatedKids = [...kids];
                  updatedKids[index][field.valueKey] = e.target.value;
                  if (field.isDob) {
                    updatedKids[index].age = calculateAge(e.target.value);
                  }
                  setKids(updatedKids);
                }}
                required={field.required}
              />
            </span>
          ))}
        </div>
      ))}
    
      <div className="label_total">  
          Total Monthly Household Income: ${calculateTotalHouseholdIncome().toLocaleString()}
      </div>  

      <button className='form_button' onClick={handleAddAdult}>+ Add New Adult</button>
      <button className='form_button' onClick={handleAddChild}>+ Add New Child</button>
      <div className='form_tab'>
          <textarea
            id='step1Notes'
            style={{width: '100%', padding:'10px', resize: 'vertical'}}
            placeholder="Add Notes..."          
            rows={7}
            cols={50}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          /> 
      </div>  
    </div>
  );  
}

export default StepPersonalInfo;
