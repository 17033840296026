// Icons.js

import icoPeople from './icons/ico_people.png'
import icoProperties from './icons/ico_properties.png';
import icoCreditcard from './icons/ico_creditcard.png';
import icoLoans from './icons/ico_loans.png';
import icoInvestments from './icons/ico_investments.png';
import icoExpenses from './icons/ico_expenses.png';
import icoPolicies from './icons/ico_policies.png';
import icoSummaries from './icons/ico_summaries.png';
import icoDonwload from './icons/ico_download.png';
import icoOpen from './icons/ico_open.png';
import icoNew from './icons/ico_new.png';
import icoNoavatar from './icons/ico_noavatar.png';
import icoAdd from './icons/ico_add.png';
import icoDelete from './icons/ico_delete.png';

const icons = {
    icoPeople,
    icoProperties,
    icoCreditcard,
    icoLoans,
    icoInvestments,
    icoExpenses,
    icoPolicies,
    icoSummaries,
    icoDonwload,
    icoOpen,
    icoNew,
    icoAdd,
    icoDelete,
    icoNoavatar
};

export default icons;
