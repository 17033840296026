import React, { useImperativeHandle, forwardRef } from 'react';
import { jsPDF } from 'jspdf';

const StepPdf = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({ exportPDF }));

  const exportPDF = () => {
    const doc = new jsPDF('p', 'pt', 'letter');    
    const customFont = 'Arial';
    const footerData = [['Financial Strategy - Cash Flow']];
    const contentElement = document.getElementById('step_preview');
   
    doc.html(contentElement.innerHTML, {
      callback: function () {
        console.log()
        const totalPages = doc.internal.getNumberOfPages();
        const docHeight = doc.internal.pageSize.getHeight();
        const docWidth = doc.internal.pageSize.getWidth();
        for (let i = 1; i <= totalPages; i++) {                    
          doc.setPage(i);
          doc.setFontSize(8);                    
          //doc.line(20, 750, docWidth - 20, 750); 
          doc.text(`${footerData}`, 40, docHeight - 20,);
          doc.text(`${i}/${totalPages}`, docWidth - 50, docHeight - 20,);                    
        }        
        const filename = localStorage.getItem('filename') + '.pdf'
        console.log(filename)
        doc.save(filename);        
      },
      x: 0,
      y: -25,
      margin: [25, 0, 60, 0],
      html2canvas: {        
        scale: 0.63,
        letterRendering: true,
        useCORS: true,
        //logging: true,
        width: 1000,        
        windowWidth: contentElement.offsetWidth,
        windowHeight: contentElement.offsetHeight,        
        onclone: (cloneDoc) => {
          cloneDoc.body.style.fontFamily = customFont;
        },
      },
    });
  };

  return <div className='summaries_container'></div>;
});

export default StepPdf;
