import React, { useEffect, useRef } from 'react';

function StepProperties({
    properties, setProperties,
    notes, setNotes,
    totalMonthlyPropertyExpenses, setTotalMonthlyPropertyExpenses,
    totalProperties, setTotalProperties,
    setCompletionStatusInParent,
}) {
    const lastAddedFirstInputRefProperty = useRef(null);

    const handleAddProperty = () => {
        setProperties([
            ...properties,
            {
                propertyName: '',
                location: '',
                type: 'No Mortgage',
                currentValue: '',
                financedAmount: '',
                startDate: '',
                durationOfLoan: '',
                mortgageRenewalDate: '',
                interestRate: '',
                currentLoanBalance: '',
                monthlyMortgage: '',
                monthlyInsuranceExpense: '',
                monthlyPropertyTax: '',
                monthlyPropertyInsurance: '',
                monthlyUtilities: '',
                monthlyCommunityFees: '',
            },
        ]);
        setTimeout(() => {
            if (lastAddedFirstInputRefProperty.current) {
                lastAddedFirstInputRefProperty.current.focus();
            }
        }, 0);
    };

    const handleDeleteProperty = (index) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this property record?');
        if (shouldDelete) {
            const updatedProperties = [...properties];
            updatedProperties.splice(index, 1);
            setProperties(updatedProperties);
        }
    };

    const fields = [
        { label: 'Property Name', type: 'text', valueKey: 'propertyName', forTypes: ['With Mortgage', 'No Mortgage'], required: true },
        { label: 'Location', type: 'text', valueKey: 'location', forTypes: ['With Mortgage'] },
        { label: 'Current Value', type: 'number', valueKey: 'currentValue', className: 'input_dollar', forTypes: ['With Mortgage', 'No Mortgage'], required: true },
        { label: 'Financed Amount', type: 'number', valueKey: 'financedAmount', className: 'input_dollar', forTypes: ['With Mortgage'] },
        { label: 'Equity', type: 'text', valueKey: 'equity', className: 'input_dollar', forTypes: ['With Mortgage'], readOnly: true },
        { label: 'Start Date', type: 'date', valueKey: 'startDate', forTypes: ['With Mortgage'] },
        { label: 'Duration of Loan (in months)', type: 'number', valueKey: 'durationOfLoan', className: 'input_number', forTypes: ['With Mortgage'] },
        { label: 'Mortgage Renewal Date', type: 'date', valueKey: 'mortgageRenewalDate', forTypes: ['With Mortgage'] },
        { label: 'Interest Rate', type: 'number', valueKey: 'interestRate', className: 'input_percentage', forTypes: ['With Mortgage'] },
        { label: 'Current Loan Balance', type: 'number', valueKey: 'currentLoanBalance', className: 'input_dollar', forTypes: ['With Mortgage'] },
        { label: 'Monthly Mortgage', type: 'number', valueKey: 'monthlyMortgage', className: 'input_dollar', forTypes: ['With Mortgage'], readOnly: true },
        { label: 'Monthly Mortgage Insurance Expense', type: 'number', valueKey: 'monthlyInsuranceExpense', className: 'input_dollar', forTypes: ['With Mortgage', 'No Mortgage'] },
        { label: 'Monthly Property Tax', type: 'number', valueKey: 'monthlyPropertyTax', className: 'input_dollar', forTypes: ['With Mortgage', 'No Mortgage'] },
        { label: 'Monthly Property Insurance', type: 'number', valueKey: 'monthlyPropertyInsurance', className: 'input_dollar', forTypes: ['With Mortgage', 'No Mortgage'] },
        { label: 'Monthly Utilities', type: 'number', valueKey: 'monthlyUtilities', className: 'input_dollar', forTypes: ['With Mortgage', 'No Mortgage'] },
        { label: 'Monthly Community or Other Fees', type: 'number', valueKey: 'monthlyCommunityFees', className: 'input_dollar', forTypes: ['With Mortgage', 'No Mortgage'] },
    ];

    const calculateTotalPropertyValue = () => {
        return properties.reduce((acc, property) => acc + (parseFloat(property.currentValue) || 0), 0);
    };
    
    const calculateTotalMonthlyExpenses = () => {
        return properties.reduce((acc, property) => {
            const propertyExpense = 
                (parseFloat(property.monthlyMortgage) || 0) +
                (parseFloat(property.monthlyInsuranceExpense) || 0) +
                (parseFloat(property.monthlyPropertyTax) || 0) +
                (parseFloat(property.monthlyPropertyInsurance) || 0) +
                (parseFloat(property.monthlyUtilities) || 0) +
                (parseFloat(property.monthlyCommunityFees) || 0);
            return acc + propertyExpense;
        }, 0);        
    };
    

    const calculateMonthlyMortgage = (financedAmount, interestRate, durationOfLoan) => {
        const monthlyInterestRate = parseFloat(interestRate || 0) / 12 / 100; // Convert annual rate to monthly and percentage to decimal
        const numberOfPayments = parseFloat(durationOfLoan || 0);
    
        if (monthlyInterestRate === 0) return (financedAmount / numberOfPayments).toFixed(2); // To handle 0% interest
    
        const monthlyMortgage = financedAmount * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) / (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
        return monthlyMortgage.toFixed(2);
    };
    

    const calculateEquity = (currentValue, financedAmount) => {
        return (parseFloat(currentValue || 0) - parseFloat(financedAmount || 0));
    };

    const calculateCompletionStatus = () => {
        let propertyStatus = 0;
        // Dynamically fetch required fields
        const requiredFields = fields.filter(field => field.required).map(field => field.valueKey);
        // Check property completion
        properties.forEach(property => {
            requiredFields.forEach(field => {
                if (property[field] && property[field].trim() !== "") {
                    propertyStatus += 1 / (properties.length * requiredFields.length);
                }
            });
        });
        return Math.round(propertyStatus * 100); // Return as a percentage
    };

    useEffect(() => {
        const status = calculateCompletionStatus();
        const totalExpenses = calculateTotalMonthlyExpenses();
        const totalProperties = calculateTotalPropertyValue();
        setTotalProperties(totalProperties);
        setTotalMonthlyPropertyExpenses(totalExpenses);
        setCompletionStatusInParent(status); // Update the status in parent
    }, [properties]);

    return (
        <div className="properties-container">
            {properties.map((property, index) => (
                <div className='form_tab' key={index}>
                    <div className='delete_button' title="delete" onClick={() => handleDeleteProperty(index)} />
                    <h2>Property Information → <span className='highlight_label'> Property {index + 1} </span></h2>
                    <span className='form_row' style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '25px 0px 25px 10px',
                    }}>                        
                        <label htmlFor={'propertyRadio' + index} style={{paddingRight: '40px' }}>
                            <strong style={{paddingRight: '20px' }}>Type of Property:</strong>
                            <input
                                id ={'propertyRadio'+index}
                                type="radio"
                                name={`propertyType-${index}`}
                                value="With Mortgage"
                                checked={property.type === "With Mortgage"}
                                onChange={(e) => {
                                    const updatedProperties = [...properties];
                                    updatedProperties[index].type = e.target.value;                                                                        
                                    setProperties(updatedProperties);
                                }}
                            />
                            With Mortgage
                        </label>
                        <label htmlFor={'property'+index}>
                            <input  
                                id={'property'+index}
                                type="radio"
                                name={`propertyType-${index}`}
                                value="No Mortgage"
                                checked={property.type === "No Mortgage"}
                                onChange={(e) => {
                                    const updatedProperties = [...properties];
                                    updatedProperties[index].type = e.target.value;
                                    setProperties(updatedProperties);
                                }}
                            />
                            No Mortgage
                        </label>
                    </span>

                    {fields.filter(field => field.forTypes.includes(property.type)).map((field, fieldIndex) => {
                        return (
                            <span className='form_field' key={field.valueKey}>
                                <label htmlFor={'property'+index+fieldIndex}>{field.label}</label>
                                <input
                                    id={'property'+index+fieldIndex}
                                    type={field.type}
                                    className={field.className}
                                    //set focus to first field when added new record
                                    ref={fieldIndex === 0 && index === properties.length - 1 ? lastAddedFirstInputRefProperty : null}
                                    value={
                                        field.valueKey === 'equity' && property.type === 'With Mortgage' ? calculateEquity(property.currentValue, property.financedAmount) :
                                            field.valueKey === 'monthlyMortgage' ? calculateMonthlyMortgage(property.financedAmount, property.interestRate, property.durationOfLoan) :
                                                property[field.valueKey]
                                    }
                                    onChange={(e) => {
                                        const updatedProperties = [...properties];
                                        updatedProperties[index][field.valueKey] = e.target.value;
                                        if (['financedAmount', 'interestRate', 'durationOfLoan'].includes(field.valueKey)) {
                                            updatedProperties[index]['monthlyMortgage'] = calculateMonthlyMortgage(
                                                updatedProperties[index].financedAmount, 
                                                updatedProperties[index].interestRate, 
                                                updatedProperties[index].durationOfLoan
                                            );
                                        }
                                        setProperties(updatedProperties);
                                    }}
                                    tabIndex={field.readOnly ? "-1" : undefined}
                                    required={field.required}
                                />
                            </span>
                        );
                    })}

                </div>
            ))}
            <div className="label_total">
                Total Property Value: ${totalProperties?.toLocaleString()}<br />
                Total Property Monthly Expenses: ${totalMonthlyPropertyExpenses?.toLocaleString()}                
            </div>

            <button className='form_button' onClick={handleAddProperty}>+ Add Property</button>
            <div className='form_tab'>
                <textarea
                    id='step2Notes'
                    style={{ width: '100%', padding: '10px', resize: 'vertical' }}
                    placeholder="Add Notes..."
                    rows={7}
                    cols={50}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
            </div>
        </div>
    );
}
export default StepProperties;