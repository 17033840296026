import React from 'react';
import coverageDefault from './host_tools.json';
import { toDollar } from './localFunctions';

function StepSummaries()
{        
    const notes = text => (
        <div className='summaries_notes_container'>
            <strong>Notes</strong>:
            <div className='summaries_notes'>
                {text.split('\n').map((item, key) => (<span key={key}>{item}<br /></span>))}                
            </div>            
            <hr/>
        </div>
        );

        const CropHeader = (word) => {
            if (typeof word !== 'string' || word.length === 0) { return ''; }
        
            // Insert a <br /> before each uppercase letter except for the first one
            const splitWords = word.replace(/(?<!^)([A-Z])/g, '<br />$1').toUpperCase();
        
            return <div dangerouslySetInnerHTML={{ __html: splitWords }} />;
        }
        
    
    const data = JSON.parse(sessionStorage.getItem('cashflowData'));  
    if (!data) {return <div>No data available</div>;}

    const renderTable = (groupName, items) => {
        if (!items || items.length === 0) {return null;}
        const headers = Object.keys(items[0]);
            return (
            <div className='summaries_section_container'>
                <h2 className='summaries_headers'>{groupName.toUpperCase()}</h2>
                <table>
                    <thead>
                    <tr>
                      {headers.map((header, index) => (
                          <th key={`header-${index}`} className={groupName === "Properties" ? "th small" : "th"}>
                              {CropHeader(header)}
                          </th>
                      ))}
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item, index) => (
                        <tr key={`item-${index}`}>
                            {headers.map((header) => (
                                <td key={`item-${index}-${header}`} className={groupName === "Properties" ? "summaries_th_small" : ""}>
                                    {header === 'extraPayments' ? (
                                        <ExtraPaymentsTable extraPayments={item[header]} />
                                    ) : (
                                        item[header]
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>          
            </div>
            );
    };

    const ExtraPaymentsTable = ({ extraPayments }) => {
        if (!extraPayments || extraPayments.length === 0) {
        return null;
        }        
        const extraPaymentHeaders = Object.keys(extraPayments[0]);
        return (
        <div>
            <table className="table">
            <thead>
                <tr>
                {extraPaymentHeaders.map((header, index) => (
                    <th key={`extraPaymentHeader-${index}`} className="table-header">
                        {header}
                    </th>
                ))}
                </tr>
            </thead>
            <tbody>
              {extraPayments.map((payment, index) => (
                  <tr key={`payment-${index}`}>
                      {extraPaymentHeaders.map((header) => (
                          <td key={`payment-${index}-${header}`} className="table-cell">
                              {payment[header]}
                          </td>
                      ))}
                  </tr>
              ))}
            </tbody>
            </table>        
        </div>
        );
    };
    

// TOTALS
const renderTotalsTable = () => {
    if (!data || !data.householdPersonalInformation) return null;
    
    let totalMonthlyIncome = data.householdPersonalInformation.adults.reduce((total, adult) => {
      const netIncome = parseFloat(adult.monthlyNetIncome) || 0;
      const investmentIncome = parseFloat(adult.monthlyInvestmentIncome) || 0;
      const otherIncome = parseFloat(adult.monthlyOtherIncome) || 0;
      return total + netIncome + investmentIncome + otherIncome;
    }, 0);
    let totalYearlyIncome = totalMonthlyIncome * 12;
    let totalPropertiesValues = 0;
    let totalPropertiesBalances = 0;
    let totalMonthlyPropertyExpenses = data.properties.properties.reduce((total, e) => {
        const monthlyMortgage = parseFloat(e.monthlyMortgage) || 0;
        const monthlyInsuranceExpense = parseFloat(e.monthlyInsuranceExpense) || 0;
        const monthlyPropertyTax = parseFloat(e.monthlyPropertyTax) || 0;
        const monthlyPropertyInsurance = parseFloat(e.monthlyPropertyInsurance) || 0;
        const monthlyUtilities = parseFloat(e.monthlyUtilities) || 0;
        const monthlyCommunityFees = parseFloat(e.monthlyCommunityFees) || 0;        
        totalPropertiesValues += parseFloat(e.currentValue) || 0;
        totalPropertiesBalances += parseFloat(e.currentLoanBalance) || 0;
        return total +
            monthlyMortgage +
            monthlyInsuranceExpense +
            monthlyPropertyTax +
            monthlyUtilities +
            monthlyPropertyInsurance +
            monthlyCommunityFees;
      }, 0);
    let totalYearlyPropertyExpenses = totalMonthlyPropertyExpenses * 12;
    let totalMonthlyCreditsPayments = 0;
    const totalCreditsBalances = data.credits.credits.reduce((total, e) => {
        totalMonthlyCreditsPayments += parseFloat(e.monthlyMinimumPayment) || 0;
        return total + parseFloat(e.currentBalance) || 0;
      }, 0);    
    const totalYearlyCreditsPayments = totalMonthlyCreditsPayments * 12;  
    
    let totalMonthlyLoansPayments = 0;
    const totalLoansBalances = data.loans.loans.reduce((total, e) => {
        totalMonthlyLoansPayments += parseFloat(e.monthlyPayment) || 0;
        return total + parseFloat(e.loanBalance) || 0;
      }, 0);    
    const totalYearlyLoansPayments = totalMonthlyLoansPayments * 12;  

    let totalMonthlyInvesmentsAllotments = 0;
    const totalInvestmentsBalances = data.investments.investments.reduce((total, e) => {
        totalMonthlyInvesmentsAllotments += parseFloat(e.monthlyAllotment) || 0;
        return total + parseFloat(e.currentBalance) || 0;
      }, 0);    
    const totalYearlyInvesmentsAllotments = totalMonthlyInvesmentsAllotments * 12;  
    const totalMonthlyExpenses = data.expenses.expenses.reduce((total, e) => total + (parseFloat(e.amount) || 0), 0);    
    const totalYearlyExpenses = totalMonthlyExpenses * 12;  
    const totalMonthlyPremiums = data.policies.policies.reduce((total, e) => total + (parseFloat(e.premiumAmount) || 0), 0);  
    const totalYearlyPremiums = totalMonthlyPremiums * 12;      
    
 // Create a new MAP for COVERAGES to store coverages and total income for each owner
    const ownerDataMap = new Map();
    // Iterate through policies and householdPersonalInformation to calculate total coverages and total income for each owner
    data.policies.policies.forEach(policy => {
        const owner = policy.owner;
        const type = policy.type;        
        const coverage = parseFloat(policy.coverage) || 0;

            // Calculate age based on data.householdPersonalInformation.adults and kids
            const ownerInfo = [...data.householdPersonalInformation.adults, ...data.householdPersonalInformation.kids]
                  .find(person => person.firstName.trim() + ' ' + person.lastName.trim() === owner.trim());
              const age = ownerInfo ? ownerInfo.age : 0; 
              if (!ownerInfo) {
                alert("The name of the owner in Policies does not match the information in Personal Information.  Review and update the related information.");
                return;
              }


            // Initialize owner's data if not already present
            if (!ownerDataMap.has(owner)) {
                ownerDataMap.set(owner, {
                Owner: owner,
                Age: age,
                "TotalCriticalCoverages": 0,
                "TotalOtherCoverages": 0,
                "TotalIncome": 0,
                });
            }

            // Update coverages based on policy type
                const ownerData = ownerDataMap.get(owner);
                if (type === "Critical Illness Insurance") {
                    ownerData["TotalCriticalCoverages"] += coverage;
                } else {
                    ownerData["TotalOtherCoverages"] += coverage; // Add to "Total Other Coverages" only if it's not critical
                }

            // Calculate total income for the owner (sum of all income types)
            const monthlyNetIncome = parseFloat(ownerInfo.monthlyNetIncome) || 0;
            const monthlyInvestmentIncome = parseFloat(ownerInfo.monthlyInvestmentIncome) || 0;
            const monthlyOtherIncome = parseFloat(ownerInfo.monthlyOtherIncome) || 0;
            const totalIncome = monthlyNetIncome + monthlyInvestmentIncome + monthlyOtherIncome;
            ownerData["TotalIncome"] = totalIncome;
        });             

    // Define the 'rows' array
    const rows = [
      { column: "Income", property:"Household Income", monthlyValue: totalMonthlyIncome, yearlyValue: totalYearlyIncome, monthly: true, yearly: true },
      { column: "Properties", property: "Properties-Values", value: totalPropertiesValues},
      { column: "Debts", property: "Properties-Balances", value: totalPropertiesBalances},
      { column: "Expenses", property: "Properties-Expenses", monthlyValue: totalMonthlyPropertyExpenses, yearlyValue: totalYearlyPropertyExpenses, monthly: true, yearly: true },
      { column: "Debts", property: "Credits-Balances", value: totalCreditsBalances},
      { column: "Expenses", property: "Credits-Payments", monthlyValue: totalMonthlyCreditsPayments, yearlyValue: totalYearlyCreditsPayments, monthly: true, yearly: true },
      { column: "Debts", property: "Loans-Balances", value: totalLoansBalances},
      { column: "Expenses",  property: "Loans-Payments", monthlyValue: totalMonthlyLoansPayments, yearlyValue: totalYearlyLoansPayments, monthly: true, yearly: true },
      { column: "Properties", property: "Investments-Balances", value: totalInvestmentsBalances},
      { column: "Expenses", property: "Investments-Allotments", monthlyValue: totalMonthlyInvesmentsAllotments, yearlyValue: totalYearlyInvesmentsAllotments, monthly: true, yearly: true },
      { column: "Expenses", property: "Expenses-Bills", monthlyValue: totalMonthlyExpenses, yearlyValue: totalYearlyExpenses, monthly: true, yearly: true },
      { column: "Expenses", property: "Premiums-Policies", monthlyValue: totalMonthlyPremiums, yearlyValue: totalYearlyPremiums, monthly: true, yearly: true },
    ];
    
    // Calculate sums for each column
    const totalPropertiesSum = rows
    .filter((row) => row.column === "Properties")
    .reduce((total, row) => total + row.value, 0);
    const totalDebtsSum = rows
    .filter((row) => row.column === "Debts")
    .reduce((total, row) => total + row.value, 0);
    const totalIncomeMonthlySum = rows
    .filter((row) => row.column === "Income" && row.monthly)
    .reduce((total, row) => total + row.monthlyValue, 0);
    const totalIncomeYearlySum = rows
    .filter((row) => row.column === "Income" && row.yearly)
    .reduce((total, row) => total + row.yearlyValue, 0);
    const totalExpensesMonthlySum = rows
    .filter((row) => row.column === "Expenses" && row.monthly)
    .reduce((total, row) => total + row.monthlyValue, 0);
    const totalExpensesYearlySum = rows
    .filter((row) => row.column === "Expenses" && row.yearly)
    .reduce((total, row) => total + row.yearlyValue, 0);


    const netCashflow = totalIncomeMonthlySum - totalExpensesMonthlySum;
    const netWorth = totalPropertiesSum - totalDebtsSum;
    // Convert the map values to an array for COVERAGES
    const ownerDataArray = [...ownerDataMap.values()];  
    
    // Return the rendered table
    return (
      <div id='Summaries_Totals'>
        <h2>TOTALS</h2>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>PARTICULARS</th>
                <th>PROPERTIES</th>
                <th>DEBTS</th>
                <th>INCOME<br/>(Monthly)</th>
                <th>INCOME<br/>(Yearly)</th>
                <th>EXPENSES<br/>(Monthly)</th>
                <th>EXPENSES<br/>(Yearly)</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => {
                let monthlyValue = row.monthly ? row.monthlyValue : '';
                let yearlyValue = row.yearly ? row.yearlyValue : '';
                return (
                  <tr key={`row-${index}`}>
                    <td className="table-cell">{index + 1}</td>
                    <td className="table-cell">{row.property}</td>
                    <td className="table-cell">{(["Properties"].includes(row.column)) ? toDollar(row.value):''}</td>
                    <td className="table-cell">{(["Debts"].includes(row.column)) ? toDollar(row.value): ''}</td>
                    <td className="table-cell">{["Income"].includes(row.column)? toDollar(monthlyValue):''} </td>
                    <td className="table-cell">{["Income"].includes(row.column)? toDollar(yearlyValue):''} </td>
                    <td className="table-cell">{["Expenses"].includes(row.column)? toDollar(monthlyValue):''} </td>
                    <td className="table-cell">{["Expenses"].includes(row.column)? toDollar(yearlyValue):''} </td>
                  </tr>
                );
              })}
              {/* Row for TOTALS */}
                <tr>
                <td>{"-"}</td>
                <td className='totals'>{"TOTALS"}</td>
                <td className='totals'>{toDollar(totalPropertiesSum)}</td>
                <td className='totals'>{toDollar(totalDebtsSum)}</td>
                <td className='totals'>{toDollar(totalIncomeMonthlySum)}</td>
                <td className='totals'>{toDollar(totalIncomeYearlySum)}</td>
                <td className='totals'>{toDollar(totalExpensesMonthlySum)}</td>
                <td className='totals'>{toDollar(totalExpensesYearlySum)}</td>
            </tr>
            </tbody>
          </table>
                <div className='summaries_net'>
                    <span style={{ color: netCashflow <= 0 ? 'Red' : 'Black' }}>
                        NET CASHFLOW : {toDollar(netCashflow)}
                    </span>
                    <span style={{ color: netWorth <= 0 ? 'Red' : 'Black' }}>
                        NET WORTH : {toDollar(netWorth)}
                    </span>
                </div>

        {/* POLICIES - COVERAGES*/}
        <h2>COVERAGES</h2>
        <table>
            <thead>
              <tr>
                <th>#</th>
                <th>OWNER</th>
                <th>CURRENT<br/>COVERAGE</th>
                <th>YOU MAY<br/>NEED</th>
                <th>ADDITIONAL<br/>COVERAGE</th>
                <th>CURRENT<br/>CRITICAL<br/>ILLNESS</th>
                <th>YOU MAY<br/>NEED</th>
                <th>ADDITIONAL<br/>COVERAGE</th>                
              </tr>
            </thead>
            <tbody>                           
              { 
                ownerDataArray.map((row, index) => {                    
                  //let neededCoverage = (coverageDefault.COVERAGES['Retirement Age'] - row.Age) * (row.TotalIncome * 12) + (data.householdPersonalInformation.kids.length * 25000);
                  const age = row.Age;
                  const coverageBasedObject = coverageDefault.COVERAGES.CoverageTable.find(range => {
                    const [start, end] = range.AgeRange.split('-').map(Number);
                    return age >= start && age <= end;
                  });
                  const coverageBased = coverageBasedObject ? parseFloat(coverageBasedObject.Based) : 1;
                  const educationFund = parseFloat(coverageDefault.COVERAGES['Education Fund']) || 1;
                  const additionalAmountTotal = Object.values(coverageDefault.COVERAGES['Additional Amount']).reduce((total, value) => {
                    return total + parseFloat(value);
                  }, 0);               
                  let neededCoverage = totalDebtsSum + (data.householdPersonalInformation.kids.length * educationFund) + (coverageBased * row.TotalIncome * 12) + additionalAmountTotal;

                  let addCoverage = neededCoverage - row.TotalOtherCoverages;
                  let neededCI = row.TotalIncome * 12 * coverageDefault.COVERAGES['Critical Illness Years'];
                  let addCI = neededCI - row.TotalCriticalCoverages;

                  return (
                    <tr key={`ownerData-${index}`}>
                        <td className="table-cell">{index + 1}</td>
                        <td className="table-cell">{row.Owner}</td>
                        <td className="table-cell">{(toDollar(row.TotalOtherCoverages))}</td>
                        <td className="table-cell">{(toDollar(neededCoverage))}</td>
                        <td className="higlight_data">{(toDollar(addCoverage <=0 ? 0 : addCoverage || 0))}</td>
                        <td className="table-cell">{(toDollar(row.TotalCriticalCoverages))}</td>
                        <td className="table-cell">{(toDollar(neededCI))}</td>
                        <td className="higlight_data">{(toDollar(addCI))}</td>
                    </tr>
                    );
                })
              }              
            </tbody>
        </table>
        </div>
      </div>
    );    
  };
 
    
return (
    <div className="form_tab">
        <div id='summaries_container' className="summaries_container"
        >        
                {renderTable('Household Personal Information - Adults', data.householdPersonalInformation.adults)}            
                {renderTable('Household Personal Information - Kids',data.householdPersonalInformation.kids)}            
                {notes(data.householdPersonalInformation.notesInfo)}                        
                            
                {renderTable('Properties', data.properties.properties)}                             
                {notes(data.properties.notesProperties)}
            
                {renderTable('Credits', data.credits.credits)}
                {notes(data.credits.notesCredits)}
                
                {renderTable('Loans', data.loans.loans)}
                {notes(data.loans.notesLoans)}
                
                {renderTable('Investments', data.investments.investments)}
                {notes(data.investments.notesInvestments)}
                
                {renderTable('Expenses', data.expenses.expenses)}
                {notes(data.expenses.notesExpenses)}
                
                {renderTable('Policies', data.policies.policies)}
                {notes(data.policies.notesPolicies)}            
                
                {renderTotalsTable()}
        </div>
    </div>
  );
};

export default StepSummaries;
