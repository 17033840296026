//Arkeo - CASHFLOW

import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import StepPersonalInfo from './StepPersonalInfo';
import StepProperties from './StepProperties';
import StepCredits from './StepCredits';
import StepLoans from './StepLoans';
import StepInvestments from './StepInvestments';
import StepExpenses from './StepExpenses';
import StepPolicies from './StepPolicies';
import StepSummaries from './StepSummaries';
import StepIncomplete from './StepIncomplete';
import './Cashflow.css';
import icons from './components/Icons';
import { saveAs } from 'file-saver';


function Cashflow() {
  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepName, setCurrentStepName] = useState('Household');
  const stepNames = ['Household', 'Properties', 'Credits', 'Loans', 'Investments', 'Expenses', 'Policies', 'Summaries'];
  
  
  // Lifted states
  const [adults, setAdults] = useState([
    {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      dob: '',
      age: '',
      monthlyNetIncome: '',
      monthlyInvestmentIncome: '',
      monthlyOtherIncome: '',
    },
  ]);
  const [kids, setKids] = useState([]);  
  const [notesInfo, setNotesInfo] = useState('');  
  const [statusInfo, setStatusInfo] = useState('');
  const [properties, setProperties] = useState([]);  
  const [totalMonthlyPropertyExpenses, setTotalMonthlyPropertyExpenses] = useState('');  
  const [totalProperties, setTotalProperties] = useState('');  
  const [notesProperties, setNotesProperties] = useState('');
  const [statusProperties, setStatusProperties] = useState('');
  const [credits, setCredits] = useState([]);
  const [notesCredits, setNotesCredits] = useState('');  
  const [statusCredits, setStatusCredits] = useState('');  
  const [loans, setLoans] = useState([]);
  const [notesLoans, setNotesLoans] = useState('');
  const [statusLoans, setStatusLoans] = useState('');
  const [investments, setInvestments] = useState([]);
  const [notesInvestments, setNotesInvestments] = useState('');  
  const [statusInvestments, setStatusInvestments] = useState('');  
  const [expenses, setExpenses] = useState([]);
  const [notesExpenses, setNotesExpenses] = useState('');  
  const [statusExpenses, setStatusExpenses] = useState('');  
  const [policies, setPolicies] = useState([]);
  const [statusPolicies, setStatusPolicies] = useState('');  
  const [notesPolicies, setNotesPolicies] = useState('');  
  const [filename, setFilename] = useState('');
  const fileInputRef = useRef(null);
  const filenameInputRef = useRef(null);
  const [isContentReady, setIsContentReady] = useState(false);       
  
  //const [completionStatus, setCompletionStatus] = useState(Array(stepNames.length).fill(0));  
  const [formComplete, setFormComplete] = useState(false);
  
  const setStatusFunctions = [
    setStatusInfo,
    setStatusProperties,
    setStatusCredits,
    setStatusLoans,
    setStatusInvestments,
    setStatusExpenses,
    setStatusPolicies
  ];
  const statusFunctions = [
    statusInfo,
    statusProperties,
    statusCredits,
    statusLoans,
    statusInvestments,
    statusExpenses,
    statusPolicies
  ];
  const updateCompletionStatusForStep = (stepIndex, status) => {
    const updatedStatus = [...statusFunctions];
    updatedStatus[stepIndex] = status;
    //setCompletionStatus(updatedStatus);    
    if (stepIndex >= 0 && stepIndex < statusFunctions.length) {
      setStatusFunctions[stepIndex](status);
    }
  };

  const checkCompletionStatus = (statusArray) => {    
    const isComplete = statusArray.every(item => item === 100);    
    setFormComplete(isComplete ? true : false);
    setIsContentReady(isComplete ? true : false);    
  };

  const getFullName = (individual) => {
    return `${individual.firstName} ${individual.lastName}`;
  }

  const getAllFullNames = () => {
    const adultNames = adults.map(getFullName);
    const kidNames = kids.map(getFullName);
    return [...adultNames, ...kidNames];
  }


  const triggerFileUpload = () => {fileInputRef.current.click();};

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const fileNameWithoutExt = file.name.replace(/\.[^/.]+$/, "");
    setFilename(fileNameWithoutExt);
    localStorage.setItem('filename', fileNameWithoutExt);
    setCurrentStep(1);    
    const reader = new FileReader();
    reader.onload = (evt) => {
      try {
        const data = JSON.parse(evt.target.result);
  
        // Household Personal Information
        if (data.householdPersonalInformation) {
          setAdults(data.householdPersonalInformation.adults || []);
          setKids(data.householdPersonalInformation.kids || []);
          setNotesInfo(data.householdPersonalInformation.notes || "");
          setStatusInfo(data.householdPersonalInformation.status || 0);
        }
  
        // Properties
        if (data.properties) {
          setProperties(data.properties.properties || []);
          setNotesProperties(data.properties.notes || "");
          setTotalProperties(data.properties.properties.totalProperties || "");
          setTotalMonthlyPropertyExpenses(data.properties.totalMonthlyPropertyExpenses || "");
          setStatusProperties(data.properties.status || 0);
        }
  
        // Credits
        if (data.credits) {
          setCredits(data.credits.credits || []);
          setNotesCredits(data.credits.notes || "");
          setStatusCredits(data.credits.status || 0);
        }
  
        // Loans
        if (data.loans) {
          setLoans(data.loans.loans || []);
          setNotesLoans(data.loans.notes || "");
          setStatusLoans(data.loans.status || 0);
        }
  
        // Investments
        if (data.investments) {
          setInvestments(data.investments.investments || []);
          setNotesInvestments(data.investments.notes || "");
          setStatusInvestments(data.investments.status || 0);
        }
  
        // Expenses
        if (data.expenses) {
          setExpenses(data.expenses.expenses || []);
          setNotesExpenses(data.expenses.notes || "");
          setStatusExpenses(data.expenses.status || 0);
        }
  
        // Policies
        if (data.policies) {
          setPolicies(data.policies.policies || []);
          setNotesPolicies(data.policies.notes || "");
          setStatusPolicies(data.policies.status || 0);
        }
  
      } catch (err) {
        alert("Error parsing the JSON file.");
      }
    };
    reader.readAsText(file);
  };
  

  const saveFile = () => {
    if (!filename) {
      alert('Please enter a filename.');
      filenameInputRef.current.focus();
      return;
    }
  
    const dataToSave = {
      householdPersonalInformation: {
        adults, 
        kids, 
        notes: notesInfo, 
        status: statusInfo
      },
      properties: {
        properties: properties, 
        notes: notesProperties, 
        totalProperties: totalProperties, 
        totalMonthlyPropertyExpenses: totalMonthlyPropertyExpenses, 
        status: statusProperties
      },
      credits: {
        credits: credits, 
        notes: notesCredits, 
        status: statusCredits
      },
      loans: {
        loans: loans, 
        notes: notesLoans, 
        status: statusLoans
      },
      investments: {
        investments: investments, 
        notes: notesInvestments, 
        status: statusInvestments
      },
      expenses: {
        expenses: expenses, 
        notes: notesExpenses, 
        status: statusExpenses
      },
      policies: {
        policies: policies, 
        notes: notesPolicies, 
        status: statusPolicies
      }
    };
  
    const json = JSON.stringify(dataToSave);
    const blob = new Blob([json], { type: 'application/json' });
    saveAs(blob, `${filename}.cflow`);
    localStorage.setItem('filename', filename);
  };
  

  const triggerFileNew = () => {
    const savedData = sessionStorage.getItem('cashflowData');
    const parsedData = savedData ? JSON.parse(savedData) : {};
  
    // Check if the session storage contains any adults data
    if (parsedData.householdPersonalInformation && parsedData.householdPersonalInformation.adults && parsedData.householdPersonalInformation.adults.length > 0) {
      const confirmation = window.confirm("Are you sure you want to clear the current data and start a new session?");
      if (!confirmation) {
        return; // If user cancels, just return without clearing data
      }
    }    
    
    sessionStorage.clear();
    localStorage.setItem('filename', '');
  
    // Reset the state for each section
    setAdults([{
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      dob: '',
      age: '',
      monthlyNetIncome: '',
      monthlyInvestmentIncome: '',
      monthlyOtherIncome: '',
    }]);
    setKids([]); 
    setNotesInfo(''); 
    setStatusInfo('');
    
    setProperties([]); 
    setNotesProperties(''); 
    setTotalProperties(''); 
    setTotalMonthlyPropertyExpenses(''); 
    setStatusProperties('');
    
    setCredits([]); 
    setNotesCredits(''); 
    setStatusCredits('');
    
    setLoans([]); 
    setNotesLoans(''); 
    setStatusLoans('');
    
    setInvestments([]); 
    setNotesInvestments(''); 
    setStatusInvestments('');
    
    setExpenses([]); 
    setNotesExpenses(''); 
    setStatusExpenses('');
    
    setPolicies([]); 
    setNotesPolicies(''); 
    setStatusPolicies('');
    
    setFilename('');
    setCurrentStep(1);
    //setCompletionStatus(Array(stepNames.length).fill(0));
    filenameInputRef.current.focus();
  };
  
  
  const iconList = [
    icons.icoPeople,
    icons.icoProperties,
    icons.icoCreditcard,
    icons.icoLoans,
    icons.icoInvestments,
    icons.icoExpenses,
    icons.icoPolicies,
    icons.icoSummaries
  ];

  const steps = stepNames.map((name, index) => ({
    id: index + 1,
    name: name,
    icon: iconList[index],  
    //status:completionStatus[index]
  }));
  
  const handleNextStep = () => {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    setCurrentStepName(stepNames[nextStep - 1]);
    window.scrollTo(0, 0);
  };
  
  const handlePreviousStep = () => {
    const prevStep = currentStep - 1;
    setCurrentStep(prevStep);
    setCurrentStepName(stepNames[prevStep - 1]);
    window.scrollTo(0, 0);
  };
  
  const handleStep = (x) => {
    setCurrentStep(x);
    setCurrentStepName(stepNames[x - 1])
    window.scrollTo(0, 0);
  };
 
  useEffect(() => {
    const savedData = sessionStorage.getItem('cashflowData');    
    if (savedData) {
      const data = JSON.parse(savedData);
  
      // Using a function to help reduce redundancy
      const assignDataToState = (section, dataKey, setter) => {
        if (data[section] && data[section][dataKey] !== undefined && typeof setter === 'function') {
          setter(data[section][dataKey]);
        }
      };
  
      // Household Personal Information
      assignDataToState('householdPersonalInformation', 'adults', setAdults);
      assignDataToState('householdPersonalInformation', 'kids', setKids);
      assignDataToState('householdPersonalInformation', 'notesInfo', setNotesInfo);
      assignDataToState('householdPersonalInformation', 'statusInfo', setStatusInfo);
  
      // Properties
      assignDataToState('properties', 'properties', setProperties);
      assignDataToState('properties', 'notesProperties', setNotesProperties);
      assignDataToState('properties', 'totalProperties', setTotalProperties);
      assignDataToState('properties', 'totalMonthlyPropertyExpenses', setTotalMonthlyPropertyExpenses);
      assignDataToState('properties', 'statusProperties', setStatusProperties);
  
      // Credits
      assignDataToState('credits', 'credits', setCredits);
      assignDataToState('credits', 'notesCredits', setNotesCredits);
      assignDataToState('credits', 'statusCredits', setStatusCredits);
  
      // Loans
      assignDataToState('loans', 'loans', setLoans);
      assignDataToState('loans', 'notesLoans', setNotesLoans);
      assignDataToState('loans', 'statusLoans', setStatusLoans);
  
      // Investments
      assignDataToState('investments', 'investments', setInvestments);
      assignDataToState('investments', 'notesInvestments', setNotesInvestments);
      assignDataToState('investments', 'statusInvestments', setStatusInvestments);
  
      // Expenses
      assignDataToState('expenses', 'expenses', setExpenses);
      assignDataToState('expenses', 'notesExpenses', setNotesExpenses);
      assignDataToState('expenses', 'statusExpenses', setStatusExpenses);
  
      // Policies
      assignDataToState('policies', 'policies', setPolicies);
      assignDataToState('policies', 'notesPolicies', setNotesPolicies);
      assignDataToState('policies', 'statusPolicies', setStatusPolicies);
      
      setFilename(data.filename);
    }
  }, []);
  
    
// Save data to sessionStorage whenever it changes
useEffect(() => {
  checkCompletionStatus(statusFunctions);
  sessionStorage.setItem('cashflowData', JSON.stringify({
    householdPersonalInformation: {
      adults, kids, notesInfo, statusInfo,
    },
    properties: {
      properties, notesProperties, totalProperties, totalMonthlyPropertyExpenses, statusProperties,
    },
    credits: {
      credits, notesCredits, statusCredits,
    },
    loans: {
      loans, notesLoans, statusLoans,
    },
    investments: {
      investments, notesInvestments, statusInvestments,
    },
    expenses: {
      expenses, notesExpenses, statusExpenses,
    },
    policies: {
      policies, notesPolicies, statusPolicies,
    },
    filename, 
    statusFunctions
  }));
}, [
  adults, kids, notesInfo, statusInfo,
  properties, notesProperties, totalProperties, totalMonthlyPropertyExpenses, statusProperties,
  credits, notesCredits, statusCredits,
  loans, notesLoans, statusLoans,
  investments, notesInvestments, statusInvestments,
  expenses, notesExpenses, statusExpenses,
  policies, notesPolicies, statusPolicies,
  filename, statusFunctions
]);
  
  const stepComponents = [
    {
      component: StepPersonalInfo,
      props: {adults,setAdults,kids,setKids,
        notes: notesInfo,
        setNotes: setNotesInfo,
      },
    },
    {
      component: StepProperties,
      props: {
        properties,setProperties,
        notes: notesProperties,
        totalProperties: totalProperties,
        setTotalProperties: setTotalProperties,
        totalMonthlyPropertyExpenses: totalMonthlyPropertyExpenses,
        setTotalMonthlyPropertyExpenses: setTotalMonthlyPropertyExpenses,
        setNotes: setNotesProperties,
      },
    },
    {
      component: StepCredits,
      props: {
        credits,setCredits,
        notes: notesCredits,
        setNotes: setNotesCredits,
      },
    },
    {
      component: StepLoans,
      props: {
        loans,setLoans,
        notes: notesLoans,
        setNotes: setNotesLoans,
      },
    },
    {
      component: StepInvestments,
      props: {
        investments,setInvestments,
        notes: notesInvestments,
        setNotes: setNotesInvestments,
      },
    },
    {
      component: StepExpenses,
      props: {
        expenses,setExpenses,
        notes: notesExpenses,
        setNotes: setNotesExpenses,
      },
    },
    {
      component: StepPolicies,
      props: {
        policies,setPolicies,
        notes: notesPolicies,
        setNotes: setNotesPolicies,
        ownersOptions: getAllFullNames(),
      },
    },
    { 
      component: formComplete ? StepSummaries : StepIncomplete,
      props: {
        isContentReady: isContentReady,
      },
    },
  ];
  
  // Find the component and props based on the currentStep
  const currentStepComponent = stepComponents[currentStep - 1];

  const previewDoc = () => {
    alert("Please complete all steps and refer to their respective progress rates.")
  };
  
  return (
    <div className="cashflow">
      <div className='body_block' style={{
        backgroundColor: 'white',
        position: 'sticky',
        top: 0,
        zIndex: 10,
        borderBottom: '10px solid #CCC',
        height: '100px',
        justifyContent:'center'
      }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={require('./components/logo/logo.png')} style={{ marginRight: '40px', marginLeft: '20px' }} />
          <h1>Financial Strategy - Cash Flow</h1>
        </div>
        
        <div className='nav_buttons_container'>
            <div className="nav_buttons_bar">              
                <button className='nav_buttons_bar download' title='Save or Download Data' onClick={saveFile}/>
                <button className='nav_buttons_bar new' title='New' onClick={triggerFileNew} />
                <button className='nav_buttons_bar open' title='Open' onClick={triggerFileUpload} />
            {/* <button className='nav_buttons_bar preview' title='Preview' onClick={previewDoc} /> */}
            <>
              {
                formComplete ? (
                <Link to="/cashflow/preview">
                  <button className='nav_buttons_bar preview' title='Preview'/>
                </Link>
              ) : (
                <button className='nav_buttons_bar preview' title='Preview' onClick={previewDoc} >                  
                </button>
              )}
            </>
                <input 
                  ref={fileInputRef}
                  type="file" 
                  accept=".cflow"                     
                  name="file"
                  title='Filename'
                  onChange={handleFileChange}                            
                />
            </div>
          </div>
      </div>
      <div className='body_block' style={{
        margin: '0 auto',
        columnGap:'100px',
        flexDirection: 'row',
        backgroundColor: 'white',                       
      }}>        
        <div className='left_nav'>
          <div className="step-names">
            {steps.map((step, index) => (
              <div key={step.id} className='step_container' onClick={() => handleStep(step.id)}>
                <div className={currentStep === step.id ? 'active_step' : 'inactive_step'}>
                  <span>{step.name}</span>
                  <span className='step_status' style={{
                    color: statusFunctions[index]===100 ? 'green':'red'
                  }}>{statusFunctions[index] ? statusFunctions[index] + '%' : ''}</span>
                  <img src={step.icon} alt='menu icon' className={currentStep === step.id ? 'step_icon_active' : 'step_icon_inactive'} />                  
                </div>
              </div>
            ))}
          </div>
          <div className='navline_filler' />
        </div>
        <div className="step-details">          
          <p>Step {currentStep} - {currentStep === 6 ? `Monthly ` : ``} {currentStepName}</p>          
          <currentStepComponent.component
            {...currentStepComponent.props}
            setCompletionStatusInParent={(status) => updateCompletionStatusForStep(currentStep - 1, status)}
          />                            
        </div>

      <div className="step-navigation">
        {currentStep > 1 && (
          <button className='nav_button' onClick={handlePreviousStep}>Prev</button>
        )}
        {currentStep < stepNames.length && (
          <button className='nav_button' onClick={handleNextStep}>Next</button>
        )}
        </div>
        <div className='filename_container'>
          <label htmlFor='filename'>Filename:</label>
          <input
            id='filename'
            type="text"
            placeholder="Enter Filename"
            value={filename}
            ref={filenameInputRef}
            onChange={(e) => setFilename(e.target.value)}            
          />
        </div>
      </div>
    </div>
  );
}

export default Cashflow;