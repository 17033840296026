import React from 'react';
import './App.css';

function E404() {
  return (
    <div className='content'>
        <div className='title'>        
        <div className="title_l2">Page Not Found!</div>        
        <div className="title_l3">Oops! The page you're looking for doesn't exist.</div>        
        <div className="title_l3">Please check the URL or return to the homepage.</div>        
        </div>        
  </div>
  );
}

export default E404;