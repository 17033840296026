import React, { useRef } from 'react'; 
import StepPdf from './StepPdf';
import { toDollar } from './localFunctions';
import coverageDefault from './host_tools.json';

function StepPreview() {
  // Retrieve data from session
    const cashflowData = sessionStorage.getItem('cashflowData');
    const data = cashflowData ? JSON.parse(cashflowData) : null;
    const stepPdfRef = useRef();
    const handleGeneratePdf = () => { if (stepPdfRef.current) { stepPdfRef.current.exportPDF(); } };   
    const adults = data.householdPersonalInformation.adults;
    const kids = data.householdPersonalInformation.kids;
    const propertiesData = data.properties && data.properties.properties;
    const creditsData = data.credits && data.credits.credits;
    const loansData = data.loans && data.loans.loans;
    const investmentsData = data.investments && data.investments.investments;
    const expensesData = data.expenses && data.expenses.expenses;
    const policiesData = data.policies && data.policies.policies;

    const headerContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px' // Adjust margin as needed
      };

    const imgStyle = {
        width: '120px',
        height: 'auto',
        marginRight: '10px' // Adjust margin as needed
      };
    
    const section_headerStyle = {
        backgroundColor: 'darkgreen',
        color: 'white',
        textAlign: 'left',
        padding: '3px 10px', // Add padding to the left side
        fontWeight: 'bold',
        fontSize: '1.2em', // You can adjust this value for progressive text size
        margin: '0.25in 0 0.15in'        
        };

    const section_footerStyle = {
        backgroundColor: '#c2840e',
        color: 'white',
        textAlign: 'left',
        padding: '3px 10px', // Add padding to the left side
        fontWeight: 'bold',
        fontSize: '1.2em', // You can adjust this value for progressive text size
        margin: '0.25in 0 0.15in'        
        };
    const curFormat = num => {
        return num?toDollar(parseFloat(num)):'$ 0';
    }
    //console.log(data)

    // Compute total household income
    const totalHouseholdIncome = adults.reduce((total, adult) => {
        return total +
        parseFloat(adult.monthlyNetIncome || 0) +
        parseFloat(adult.monthlyInvestmentIncome || 0) +
        parseFloat(adult.monthlyOtherIncome || 0);
    }, 0);

    const totalCreditLimit = creditsData.reduce((total, credit) => total + parseFloat(credit.creditLimit || 0), 0);
    const totalCurrentBalance = creditsData.reduce((total, credit) => total + parseFloat(credit.currentBalance || 0), 0);
    const totalMonthlyMinimumPayment = creditsData.reduce((total, credit) => total + parseFloat(credit.monthlyMinimumPayment || 0), 0);

    // Calculate total properties
    const totalProperties = propertiesData.reduce((total, property) => total + parseFloat(property.currentValue || 0), 0);

    // Calculate total loaned amount, total monthly payment, and total loan balance
    const totalLoanedAmount = loansData.reduce((total, loan) => total + parseFloat(loan.loanedAmount || 0), 0);
    const totalMonthlyPayment = loansData.reduce((total, loan) => total + parseFloat(loan.monthlyPayment || 0), 0);
    const totalLoanBalance = loansData.reduce((total, loan) => total + parseFloat(loan.loanBalance || 0), 0);

    // Calculate total current balance and total monthly allotment
    const totalCurrentBalanceInvestments = investmentsData.reduce((total, investment) => total + parseFloat(investment.currentBalance || 0), 0);
    const totalMonthlyAllotment = investmentsData.reduce((total, investment) => total + parseFloat(investment.monthlyAllotment || 0), 0);

    // Calculate total expenses
    const totalExpenses = expensesData.reduce((total, expense) => total + parseFloat(expense.amount || 0), 0);

    // Calculate totals
    const totalHouseholdIncomeMonthly = adults.reduce((total, adult) => total + parseFloat(adult.monthlyNetIncome || 0) + parseFloat(adult.monthlyInvestmentIncome || 0) + parseFloat(adult.monthlyOtherIncome || 0), 0);
    const totalPropertiesValues = propertiesData.reduce((total, property) => total + parseFloat(property.currentValue || 0), 0);
    const totalPropertiesBalances = propertiesData.reduce((total, property) => total + parseFloat(property.currentLoanBalance || 0), 0);
    const totalPropertiesExpensesMonthly = propertiesData.reduce((total, property) => total + parseFloat(property.monthlyMortgage || 0) + parseFloat(property.monthlyInsuranceExpense || 0) + parseFloat(property.monthlyPropertyTax || 0) + parseFloat(property.monthlyPropertyInsurance || 0) + parseFloat(property.monthlyUtilities || 0) + parseFloat(property.monthlyCommunityFees || 0), 0);
    const totalCreditsBalances = creditsData.reduce((total, credit) => total + parseFloat(credit.currentBalance || 0), 0);
    const totalCreditsPaymentsMonthly = creditsData.reduce((total, credit) => total + parseFloat(credit.monthlyMinimumPayment || 0), 0);
    const totalLoansBalances = loansData.reduce((total, loan) => total + parseFloat(loan.loanBalance || 0), 0);
    const totalLoansPaymentsMonthly = loansData.reduce((total, loan) => total + parseFloat(loan.monthlyPayment || 0), 0);
    const totalInvestmentsBalances = investmentsData.reduce((total, investment) => total + parseFloat(investment.currentBalance || 0), 0);
    const totalInvestmentsAllotmentsMonthly = investmentsData.reduce((total, investment) => total + parseFloat(investment.monthlyAllotment || 0), 0);
    const totalExpensesBillsMonthly = expensesData.reduce((total, expense) => total + parseFloat(expense.amount || 0), 0);
    const totalPremiumsPoliciesMonthly = policiesData.reduce((total, policy) => total + parseFloat(policy.premiumAmount || 0), 0);
    const totalDebts = totalPropertiesBalances + totalCreditsBalances + totalLoansBalances;

    const totalCreditsPayments = creditsData.reduce((total, credit) => total + parseFloat(credit.monthlyMinimumPayment || 0), 0);
    const totalLoansPayments = loansData.reduce((total, loan) => total + parseFloat(loan.monthlyPayment || 0), 0);
    const totalInvestmentsAllotments = investmentsData.reduce((total, investment) => total + parseFloat(investment.monthlyAllotment || 0), 0);
    const totalExpensesBills = expensesData.reduce((total, expense) => total + parseFloat(expense.amount || 0), 0);
    const totalPremiumsPolicies = policiesData.reduce((total, policy) => total + parseFloat(policy.premiumAmount || 0), 0);
    
    //Calculate Net Cashflow and Worth
    const netCashflow = totalHouseholdIncomeMonthly - (totalPropertiesExpensesMonthly + totalCreditsPaymentsMonthly + totalLoansPaymentsMonthly + totalInvestmentsAllotmentsMonthly + totalExpensesBillsMonthly + totalPremiumsPoliciesMonthly);
    const netWorth = (totalPropertiesValues + totalInvestmentsBalances) - (totalPropertiesBalances + totalCreditsBalances + totalLoansBalances);

    // Create a new MAP for COVERAGES to store coverages and total income for each owner
    const ownerDataMap = new Map();
    // Iterate through policies and householdPersonalInformation to calculate total coverages and total income for each owner
    data.policies.policies.forEach(policy => {
        const owner = policy.owner;
        const type = policy.type;        
        const coverage = parseFloat(policy.coverage) || 0;

            // Calculate age based on data.householdPersonalInformation.adults and kids
            const ownerInfo = [...data.householdPersonalInformation.adults, ...data.householdPersonalInformation.kids]
                .find(person => person.firstName.trim() + ' ' + person.lastName.trim() === owner.trim());
            const age = ownerInfo ? ownerInfo.age : 0;           
            // Initialize owner's data if not already present
            if (!ownerDataMap.has(owner)) {
                ownerDataMap.set(owner, {
                Owner: owner,
                Age: age,
                "TotalCriticalCoverages": 0,
                "TotalOtherCoverages": 0,
                "TotalIncome": 0,
                });
            }

            // Update coverages based on policy type
                const ownerData = ownerDataMap.get(owner);
                if (type === "Critical Illness Insurance") {
                    ownerData["TotalCriticalCoverages"] += coverage;
                } else {
                    ownerData["TotalOtherCoverages"] += coverage; // Add to "Total Other Coverages" only if it's not critical
                }

            // Calculate total income for the owner (sum of all income types)
            const monthlyNetIncome = parseFloat(ownerInfo.monthlyNetIncome) || 0;
            const monthlyInvestmentIncome = parseFloat(ownerInfo.monthlyInvestmentIncome) || 0;
            const monthlyOtherIncome = parseFloat(ownerInfo.monthlyOtherIncome) || 0;
            const totalIncome = monthlyNetIncome + monthlyInvestmentIncome + monthlyOtherIncome;
            ownerData["TotalIncome"] = totalIncome;
        });         
    const ownerDataArray = [...ownerDataMap.values()];  

  return (
      <div className='step_preview_container'>        
          <button style={{
              position: 'fixed',
              right: '10px',
              top: '10px',
              zIndex:101
          }}
              onClick={handleGeneratePdf}>Generate PDF</button><StepPdf ref={stepPdfRef}
          />
      <div id='step_preview' className="step_preview">                     
        <div className="step_preview_body">
            <div style={headerContainerStyle}>
                <div><img style={imgStyle} src={require('./components/tools/clients/arkeo/logo.png')} alt="Logo" /></div>
                <h2>Financial Strategy - Cash Flow</h2>
            </div>
            <div className='prev_section_container'>
                <div style={section_headerStyle}>A. Household Personal Information</div>
                <h3>{adults.length > 1 ? 'Adults' : 'Adult'}<br/></h3>
                <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>                        
                    {adults.map((adult, index) => (
                        <div className='prev_household_section' key={index}>                            
                            <p><span className='prev_household_section_field'>Name:</span><span style={{textTransform:'uppercase'}}>{adult.firstName} {adult.lastName}</span></p>                        
                            <p><span className='prev_household_section_field'>Email:</span>&nbsp;{adult.email}</p>
                            <p><span className='prev_household_section_field'>Phone:</span>{adult.phone}</p>
                            <p><span className='prev_household_section_field'>DOB:</span>&nbsp;&nbsp;{adult.dob}</p>
                            <p><span className='prev_household_section_field'>Age:</span>&nbsp;&nbsp;&nbsp;&nbsp;{adult.age}</p>
                            <p><span className='prev_household_section_field'>Monthly Net Income:</span>{curFormat(adult.monthlyNetIncome)}</p>
                            <p><span className='prev_household_section_field'>Monthly Investment Income:</span>{curFormat(adult.monthlyInvestmentIncome)}</p>
                            <p><span className='prev_household_section_field'>Monthly Other Income:</span>{curFormat(adult.monthlyOtherIncome)}</p>
                        </div>
                    ))}
                </div>
                <div style={{marginBottom:'50px'}}>
                <h3>Kids</h3>
                    <table style={{ width: 'auto', borderCollapse: 'collapse' }}>
                        <thead>
                        <tr>
                            <th style={{ textAlign: 'left' }}>#</th>
                            <th style={{ textAlign: 'left' }}>Name</th>
                            <th style={{ textAlign: 'left' }}>DOB</th>
                            <th style={{ textAlign: 'left' }}>Age</th>
                        </tr>
                        </thead>
                        <tbody>
                        {kids.map((kid, index) => (
                            <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{kid.firstName} {kid.lastName}</td>
                            <td>{kid.dob}</td>
                            <td>{kid.age}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div style={{ textAlign: 'right', fontWeight: 'bold' }}>Total Monthly Household Income: ${totalHouseholdIncome.toLocaleString()}</div>

                </div>                
                <p><span style={{fontWeight:'bold', marginRight:'20px'}}>Notes:</span> {data.householdPersonalInformation.notesInfo}</p>
                <div className='prev_section_divider'/>
                    <div style={section_headerStyle}>B. Properties</div>
                    {propertiesData && propertiesData.length > 0 && (
                         <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}> 
                        {propertiesData.map((property, index) => (
                            <div className='prev_property_section' key={index}>
                            <h3>{property.propertyName}</h3>
                            <p><span className='prev_property_section_field'>Location:</span>{property.location}</p>
                            <p><span className='prev_property_section_field'>Type:</span>{property.type}</p>
                            <p><span className='prev_property_section_field'>Current Value:</span>{curFormat(property.currentValue)}</p>
                            <p><span className='prev_property_section_field'>Financed Amount:</span>{curFormat(property.financedAmount)}</p>
                            <p><span className='prev_property_section_field'>Start Date:</span>{property.startDate}</p>
                            <p><span className='prev_property_section_field'>Duration of Loan:</span>{property.durationOfLoan}</p>
                            <p><span className='prev_property_section_field'>Mortgage Renewal Date:</span>{property.mortgageRenewalDate}</p>
                            <p><span className='prev_property_section_field'>Interest Rate:</span>{property.interestRate | 0 }%</p>
                            <p><span className='prev_property_section_field'>Current Loan Balance:</span>{curFormat(property.currentLoanBalance)}</p>
                            <p><span className='prev_property_section_field'>Monthly Mortgage:</span>{curFormat(property.monthlyMortgage)}</p>
                            <p><span className='prev_property_section_field'>Monthly Insurance Expense:</span>{curFormat(property.monthlyInsuranceExpense)}</p>
                            <p><span className='prev_property_section_field'>Monthly Property Tax:</span>{curFormat(property.monthlyPropertyTax)}</p>
                            <p><span className='prev_property_section_field'>Monthly Property Insurance:</span>{curFormat(property.monthlyPropertyInsurance)}</p>
                            <p><span className='prev_property_section_field'>Monthly Utilities:</span>{curFormat(property.monthlyUtilities)}</p>
                            <p><span className='prev_property_section_field'>Monthly Community Fees:</span>{curFormat(property.monthlyCommunityFees)}</p>
                            </div>
                        ))}                        
                        </div>
                    )}
                    <div style={{ textAlign: 'right', fontWeight: 'bold' }}>Total Value of Properties:  ${totalProperties.toLocaleString()}</div>                    
                    
                    <p><span style={{ fontWeight: 'bold', marginRight: '20px' }}>Notes:</span>{data.properties.notes}</p>
                    <div className='prev_section_divider'/>
                    <div style={section_headerStyle}>C. Credits</div>
                        {creditsData && creditsData.length > 0 && (
                        <table style={{ width: 'auto', borderCollapse: 'collapse' }}>
                        <thead>
                          <tr>
                            <th style={{ textAlign: 'left' }}>Creditor</th>
                            <th style={{ textAlign: 'left' }}>Credit Limit</th>
                            <th style={{ textAlign: 'left' }}>Current Balance</th>
                            <th style={{ textAlign: 'left' }}>Interest Rate</th>
                            <th style={{ textAlign: 'left' }}>Monthly <br/>Min Pay</th>
                          </tr>
                        </thead>
                        <tbody>
                          {creditsData.map((credit, index) => (
                            <tr key={index}>
                              <td>{credit.creditor}</td>
                              <td>{curFormat(credit.creditLimit)}</td>
                              <td>{curFormat(credit.currentBalance)}</td>
                              <td>{credit.interestRate || 0}%</td>
                              <td>{curFormat(credit.monthlyMinimumPayment)}</td>
                            </tr>
                          ))}
                            <tr style={{ fontWeight: 'bold' }}>
                                <td>Total</td>
                                <td>{curFormat(totalCreditLimit)}</td>
                                <td>{curFormat(totalCurrentBalance)}</td>
                                <td></td>
                                <td>{curFormat(totalMonthlyMinimumPayment)}</td>
                            </tr>
                        </tbody>
                      </table>
                        )}                    
                    <p><span style={{ fontWeight: 'bold', marginRight: '20px' }}>Notes:</span>{data.credits.notes}</p>
                    
                    <div className='prev_section_divider' />
                    <div style={section_headerStyle}>D. Loans</div>
                        {loansData && loansData.length > 0 && (
                    <table style={{ width: 'auto', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'left' }}>Name</th>
                                <th style={{ textAlign: 'left' }}>Loaned Amount</th>
                                <th style={{ textAlign: 'left' }}>Loan Started</th>
                                <th style={{ textAlign: 'left' }}>Duration Months</th>
                                <th style={{ textAlign: 'left' }}>Interest Rate</th>
                                <th style={{ textAlign: 'left' }}>Monthly Payment</th>
                                <th style={{ textAlign: 'left' }}>Loan Balance</th>
                                <th style={{ textAlign: 'left',  whiteSpace: 'nowrap' }}>Extra Payments <br/> Month | Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loansData.map((loan, index) => (
                                <tr key={index}>
                                    <td>{loan.name}</td>
                                    <td>{curFormat(loan.loanedAmount)}</td>
                                    <td>{loan.loanStarted}</td>
                                    <td>{loan.durationInMonths}</td>
                                    <td>{loan.interestRate || 0}%</td>
                                    <td>{curFormat(loan.monthlyPayment)}</td>
                                    <td>{curFormat(loan.loanBalance)}</td>
                                    <td>
                                        {loan.extraPayments && loan.extraPayments.length > 0 && (
                                            <table style={{ width: 'auto', borderCollapse: 'collapse', border: 'none', fontSize: '12px' }}>
                                                <tbody>
                                                    {loan.extraPayments.map((extraPayment, extraIndex) => (
                                                        <tr key={extraIndex}>
                                                            <td style={{ border: 'none' }}>{extraPayment.month}</td>
                                                            <td style={{ border: 'none', whiteSpace: 'nowrap' }}>{curFormat(extraPayment.amount)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            <tr style={{ fontWeight: 'bold' }}>
                                <td>Total</td>
                                <td>{curFormat(totalLoanedAmount)}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{curFormat(totalMonthlyPayment)}</td>
                                <td>{curFormat(totalLoanBalance)}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    
                        )}
                        <p><span style={{ fontWeight: 'bold', marginRight: '20px' }}>Notes:</span>{data.loans.notes}</p>
                        <div className='prev_section_divider'/>
                        <div style={section_headerStyle}>E. Investments</div>
                            {investmentsData && investmentsData.length > 0 && (
                        <table style={{ width: 'auto', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'left' }}>Name</th>
                                <th style={{ textAlign: 'left' }}>Current Balance</th>
                                <th style={{ textAlign: 'left' }}>Monthly <br/>Allotment</th>
                                <th style={{ textAlign: 'left' }}>Provider</th>
                            </tr>
                        </thead>
                        <tbody>
                            {investmentsData.map((investment, index) => (
                                <tr key={index}>
                                    <td>{investment.name}</td>
                                    <td>{curFormat(investment.currentBalance)}</td>
                                    <td>{curFormat(investment.monthlyAllotment)}</td>
                                    <td>{investment.provider}</td>
                                </tr>
                            ))}
                            {/* Total row */}
                            <tr>
                                <td><b>Total</b></td>
                                <td><b>{curFormat(totalCurrentBalanceInvestments)}</b></td>
                                <td><b>{curFormat(totalMonthlyAllotment)}</b></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                        
                            )}
                        <p><span style={{ fontWeight: 'bold', marginRight: '20px' }}>Notes:</span>{data.investments.notes}</p>                            
                        <div className='prev_section_divider' />
                        <div style={section_headerStyle}>F. Expenses</div>
                            {expensesData && expensesData.length > 0 && (
                        <table style={{ width: 'auto', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'left' }}>Name</th>
                                    <th style={{ textAlign: 'left' }}>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {expensesData.map((expense, index) => (
                                    <tr key={index}>
                                        <td>{expense.name}</td>
                                        <td>{curFormat(expense.amount)}</td>
                                    </tr>
                                ))}
                                {/* Total row */}
                                <tr>
                                    <td><b>Total</b></td>
                                    <td><b>{curFormat(totalExpenses)}</b></td>
                                </tr>
                            </tbody>
                        </table>                        
                            )}
                        <p><span style={{ fontWeight: 'bold', marginRight: '20px' }}>Notes:</span>{data.expenses.notes}</p>
                        <div className='prev_section_divider' />
                        <div style={section_headerStyle}>G. Policies</div>
                            {policiesData && policiesData.length > 0 && (
                            <table style={{ width: 'auto', borderCollapse: 'collapse' }}>
                                <thead>
                                <tr>
                                    <th style={{ textAlign: 'left' }}>Type</th>
                                    <th style={{ textAlign: 'left' }}>Owner</th>
                                    <th style={{ textAlign: 'left' }}>Policy Number</th>
                                    <th style={{ textAlign: 'left' }}>Provider</th>
                                    <th style={{ textAlign: 'left' }}>Start Date</th>
                                    <th style={{ textAlign: 'left' }}>Premium Amount</th>
                                    <th style={{ textAlign: 'left' }}>Frequency</th>
                                    <th style={{ textAlign: 'left' }}>Coverage</th>
                                </tr>
                                </thead>
                                <tbody>
                                {policiesData.map((policy, index) => (
                                    <tr key={index}>
                                    <td>{policy.type}</td>
                                    <td>{policy.owner}</td>
                                    <td>{policy.policyNumber}</td>
                                    <td>{policy.provider}</td>
                                    <td>{policy.startDate}</td>
                                    <td>{curFormat(policy.premiumAmount)}</td>
                                    <td>{policy.frequency}</td>
                                    <td style={{whiteSpace: 'nowrap'}}>{curFormat(policy.coverage)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>                    
                            )}
                            <p><span style={{ fontWeight: 'bold', marginRight: '20px' }}>Notes:</span>{data.policies.notes}</p>
                            
                      
                        <div className='prev_section_divider' />
                            <div style={section_headerStyle}>H. Totals</div>
                            <table style={{ width: '98%', borderCollapse: 'collapse', fontSize: '12px' }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th style={{ width: '22%' }}>PARTICULARS</th>
                                        <th>PROPERTIES</th>
                                        <th style={{ width: '12%' }}>DEBTS</th>
                                        <th>INCOME (Monthly)</th>
                                        <th style={{ width: '12%' }}>INCOME (Yearly)</th>
                                        <th>EXPENSES (Monthly)</th>
                                        <th>EXPENSES (Yearly)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Household Income</td>
                                        <td></td>
                                        <td></td>
                                        <td>{toDollar(totalHouseholdIncomeMonthly)}</td>
                                        <td>{toDollar(totalHouseholdIncomeMonthly * 12)}</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Properties-Values</td>
                                        <td>{toDollar(totalPropertiesValues)}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Properties-Balances</td>
                                        <td></td>
                                        <td>{toDollar(totalPropertiesBalances)}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Properties-Expenses</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{toDollar(totalPropertiesExpensesMonthly)}</td>
                                        <td>{toDollar(totalPropertiesExpensesMonthly * 12)}</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Credits-Balances</td>
                                        <td></td>
                                        <td>{toDollar(totalCreditsBalances)}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>Credits-Payments</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{toDollar(totalCreditsPaymentsMonthly)}</td>
                                        <td>{toDollar(totalCreditsPaymentsMonthly * 12)}</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>Loans-Balances</td>
                                        <td></td>
                                        <td>{toDollar(totalLoansBalances)}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>Loans-Payments</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{toDollar(totalLoansPaymentsMonthly)}</td>
                                        <td>{toDollar(totalLoansPaymentsMonthly * 12)}</td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>Investments-Balances</td>
                                        <td>{toDollar(totalInvestmentsBalances)}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>Investments-Allotments</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{toDollar(totalInvestmentsAllotmentsMonthly)}</td>
                                        <td>{toDollar(totalInvestmentsAllotmentsMonthly * 12)}</td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td>Expenses-Bills</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{toDollar(totalExpensesBillsMonthly)}</td>
                                        <td>{toDollar(totalExpensesBillsMonthly * 12)}</td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td>Premiums-Policies</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{toDollar(totalPremiumsPoliciesMonthly)}</td>
                                        <td>{toDollar(totalPremiumsPoliciesMonthly * 12)}</td>
                                    </tr>
                                    <tr style={{ fontWeight: 'bold' }}>
                                        <td colSpan="2">TOTALS</td>
                                        <td>{toDollar(totalPropertiesValues + totalInvestmentsBalances)}</td>
                                        <td>{toDollar(totalDebts)}</td>
                                        <td>{toDollar(totalHouseholdIncomeMonthly)}</td>
                                        <td>{toDollar(totalHouseholdIncomeMonthly * 12)}</td>
                                        <td>{toDollar(totalPropertiesExpensesMonthly + totalCreditsPayments + totalLoansPayments + totalInvestmentsAllotments + totalExpensesBills + totalPremiumsPolicies)}</td>
                                        <td>{toDollar((totalPropertiesExpensesMonthly + totalCreditsPayments + totalLoansPayments + totalInvestmentsAllotments + totalExpensesBills + totalPremiumsPolicies) * 12)}</td>
                                    </tr>
                                </tbody>
                            </table>                                              
                        <div/>
                        
                      <div className='prev_section_divider' />
                        <div style={section_headerStyle}>I. Net Cashflow and Net Worth</div>
                        <div style={{ textAlign: 'center', fontSize: '25px', paddingTop :'30px' }}>
                            <span>Net Cashflow:</span>
                            <span style={{ color: netCashflow < 0 ? 'red' : 'inherit', marginLeft: '10px' }}>{toDollar(netCashflow)}</span>
                            <span style={{ marginLeft: '20px' }}>Net Worth:</span>
                            <span style={{ marginLeft: '10px' }}>{toDollar(netWorth)}</span>
                        </div>
                      <div />

                      <div className='prev_section_divider' />
                        <div style={section_footerStyle}>J. Suggested Coverages</div>
                      {policiesData && policiesData.length > 0 && (
                          <table style={{ width: '98%', borderCollapse: 'collapse', fontSize: '12px', marginBottom: '30px' }}>
                              <thead>
                              <tr>
                                <th>#</th>
                                <th>OWNER</th>
                                <th>CURRENT<br />COVERAGE</th>
                                <th>YOU MAY<br />NEED</th>
                                <th style={{ backgroundColor: '#ebeae7' }}>ADDITIONAL<br />COVERAGE</th>
                                <th>CURRENT<br />CRITICAL<br />ILLNESS</th>
                                <th>YOU MAY<br />NEED</th>
                                <th style={{ backgroundColor: '#ebeae7' }}>ADDITIONAL<br />COVERAGE</th>
                            </tr>


                              </thead>
                              <tbody>                           
                                { 
                                ownerDataArray.map((row, index) => {                    
                                    const age = row.Age;
                                    const coverageBasedObject = coverageDefault.COVERAGES.CoverageTable.find(range => {
                                    const [start, end] = range.AgeRange.split('-').map(Number);
                                    return age >= start && age <= end;
                                    });
                                    const coverageBased = coverageBasedObject ? parseFloat(coverageBasedObject.Based) : 1;
                                    const educationFund = parseFloat(coverageDefault.COVERAGES['Education Fund']) || 1;
                                    const additionalAmountTotal = Object.values(coverageDefault.COVERAGES['Additional Amount']).reduce((total, value) => {
                                    return total + parseFloat(value);
                                    }, 0);               
                                    let neededCoverage = totalDebts + (data.householdPersonalInformation.kids.length * educationFund) + (coverageBased * row.TotalIncome * 12) + additionalAmountTotal;

                                    let addCoverage = neededCoverage - row.TotalOtherCoverages;
                                    let neededCI = row.TotalIncome * 12 * coverageDefault.COVERAGES['Critical Illness Years'];
                                    let addCI = neededCI - row.TotalCriticalCoverages;

                                    return (
                                    <tr key={`ownerData-${index}`}>
                                        <td>{index + 1}</td>
                                        <td>{row.Owner}</td>
                                        <td>{toDollar(row.TotalOtherCoverages)}</td>
                                        <td >{toDollar(neededCoverage)}</td>
                                        <td style={{ backgroundColor: '#ebeae7' }} className={addCoverage <= 0 ? 'highlight_data' : 'normal_data'}>{toDollar(Math.max(addCoverage, 0))}</td>
                                        <td>{toDollar(row.TotalCriticalCoverages)}</td>
                                        <td>{toDollar(neededCI)}</td>
                                        <td style={{ backgroundColor: '#ebeae7' }} className={addCI <= 0 ? 'highlight_data' : 'normal_data'}>{toDollar(Math.max(addCI, 0))}</td>
                                    </tr>
                                    );
                                })
                                }              
                            </tbody>
                          </table>
                      )}
                      <div />                      
                        
            </div>
            </div>      
        </div>
    </div>
  );
}

export default StepPreview;
